import { Stack, Typography, Box, useTheme, Button, styled, InputAdornment, TextField } from "@mui/material";
import { useTranslations } from "next-intl";
import { roboto } from "@/utis/fonts";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { useCallback, useEffect, useState } from "react";
import Image from "next/image";
import { AnimatePresence, motion } from "framer-motion";
import NavigationPanel from "../NavigationPanel";
import LoginPopup from "../Login/LoginPopup";
import { LoginType } from "../Login/LoginPopup/common";
import RedChartUptrendIcon from "../Icons/RedChartUptrendIcon";

import arbPhoto from "../../../public/images/Arb_Half.png";
import evFormulaScreen from "../../../public/images/EV_Half.png";
import oddsScreen from "../../../public/images/OddsScreen_Half.png";
import phoneOverlay from "../../../public/images/NoBG_Half.png";

const StyledWrapper = styled(Box)(
  ({ theme }) => `
    position: relative;
    padding-bottom: 40px;
  `
);

const StyledNavigationPanel = styled(NavigationPanel)`
  button {
    background-color: inherit !important;
  }
  a {
    background-color: inherit !important;
  }
`;

const StyledBody = styled(Stack)(
  () => `
    flex-direction: row;
    width: 100%;
  `
);

const StyledContent = styled(Box)(
  ({ theme }) => `
    flex-grow: 1;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: row;
    @media (max-width: ${theme.breakpoints.values.md}px) {
      justify-content: flex-start;
    }
  `,
);

export const StyledTextField = styled(TextField)(({ theme }) => `
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    display: none;
  }
  background: ${theme.palette.grayScale.twelevepercent};
  border-radius: 8px;
  width: 100%;
  input {
    height: 16px;
    font-size: 16px;
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  @media (max-width: ${theme.breakpoints.values.md}px) {
    input {
      height: 10px;
      font-size: 12px;
    }
  }
`);

const StyledFadedBorder = styled(Box)(
  ({ theme }) => `
    width: 1px;
    position: relative;
    background: linear-gradient(to bottom, ${theme.palette.divider}, ${theme.palette.primary.main});
  `
);

function Top() {
  const theme = useTheme();
  const t = useTranslations("Home");

  const [emailAddress, setEmailAddress] = useState<string>("");
  const [showLoginPopup, setShowLoginPopup] = useState<boolean>(false);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailAddress(event.target.value.trim());
  };

  const openLoginPopup = useCallback(() => {
    setShowLoginPopup(true);
  }, []);

  const closeLoginPopup = useCallback(() => {
    setShowLoginPopup(false);
  }, []);

  const images = [arbPhoto, evFormulaScreen, oddsScreen];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 2000);
    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <StyledWrapper>
      <StyledBody>
        <StyledNavigationPanel hideBorder />
        <StyledFadedBorder />
        <StyledContent>
          <Box sx={{

            marginBottom: { xs: "250px", md: "0px" },
            paddingLeft: { xs: "15px", md: undefined },
          }}>
            <Box mt={{ xs: 2, md: 4 }} maxWidth={{ xs: 280, md: 400 }}>
              <Typography
                variant="karantinaHeader"
                sx={{
                  fontSize: { xs: "80px", md: "115px" },
                  lineHeight: { xs: "80px", md: "110px" },
                }}
              >
                {t("title")}
              </Typography>
            </Box>

            <Stack
              zIndex={2}
              direction="column"
              gap={2}
              alignItems="center"
              maxWidth={{ xs: 180, md: 400 }}
            >
              <Typography
                fontSize={{ xs: 16, md: 20 }}
                sx={{ marginBottom: "20px", color: theme.palette.text.secondary }}
              >
                <RedChartUptrendIcon
                  sx={{
                    fontSize: { xs: "12px", md: "16px" },
                  }}
                />{" "}
                {t("description")}
              </Typography>

              <StyledTextField
                variant="outlined"
                placeholder="Email Address"
                autoComplete="email"
                value={emailAddress}
                onChange={handleEmailChange}
                sx={{
                  input: {
                    "&:-webkit-autofill": {
                      WebkitBoxShadow: `0 0 0 1000px ${theme.palette.grayScale.sixpercent} inset !important`,
                      transition: "background-color 5000s ease-in-out 0s",
                    },
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MailOutlineIcon
                        sx={{
                          color: theme.palette.text.secondary,
                          fontSize: { xs: "18px", md: undefined },
                          marginBottom: { xs: "3px", md: undefined },
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
              />

              <Button
                variant="contained"
                size="large"
                fullWidth={false}
                color="secondary"
                onClick={openLoginPopup}
                sx={{
                  width: "fit-content",
                  background: theme.palette.text.primary,
                  px: "30px",
                  py: "10px",
                  fontWeight: 600,
                  color: theme.palette.basicReverse,
                  textTransform: "none",
                  ml: { xs: undefined, md: "auto" },
                  mr: { xs: "auto", md: "0px" },
                  borderRadius: "8px",
                }}
              >
                <span className={roboto.className}>{t("signup_now")}</span>
              </Button>

              <LoginPopup
                open={showLoginPopup}
                onClose={closeLoginPopup}
                email={emailAddress}
                loginType={LoginType.SIGN_UP}
              />
            </Stack>
          </Box>



          <Box
            sx={{
              marginTop: { xs: "200px", md: "50px" },
              width: { xs: "65vw", md: "380px" },
              height: { xs: "440px", md: "600px" },
              position: { xs: "absolute", md: "relative" },
              right: { xs: "0px", md: undefined },
            }}
          >
            {/* Static phone overlay that stays on top */}
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                zIndex: 2, // Ensure this stays on top
              }}
            >
              <Image
                src={phoneOverlay}
                alt="Phone Overlay"
                layout="fill"
                objectFit="cover"
              />
            </Box>

            {/* Animated screenshots underneath */}
            <AnimatePresence mode="sync">
              <motion.div
                key={currentImageIndex}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  zIndex: 1,
                }}
              >
                <Image
                  src={images[currentImageIndex]}
                  alt={`Image ${currentImageIndex + 1}`}
                  layout="fill"
                  objectFit="cover"
                />
              </motion.div>
            </AnimatePresence>
          </Box>
        </StyledContent>
      </StyledBody>

      <Box
        position="absolute"
        bottom={0}
        left={0}
        width="100%"
        height="10%"
        sx={{
          background: `linear-gradient(to top, ${theme.palette.customBackground.cards}, transparent)`,
        }}
      />
    </StyledWrapper>
  );
}

export default Top;